<template>
  <v-app id="reset">
    <v-main>
      <v-container fluid fill-height>
        <Loader :loading="loading" />
        <v-row align="center" justify="center">
          <v-col cols="12" md="5">
            <v-img class="animate__animated animate__bounceInLeft img-shadow" :src="logo" max-height="260" contain />
          </v-col>
          <v-col cols="2" class="itemsCenter d-none d-md-flex">
            <div class="vl"></div>
          </v-col>
          <v-col cols="12" md="5">
            <v-card class="animate__animated animate__bounceInRight mx-auto" elevation="5" max-width="500">
              <v-card-title color="primary" class="justify-center mb-3 main-title" v-text="'Restablecer contraseña'" />
              <v-card-text>
                <v-form ref="formReset" lazy-validation @submit.prevent>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field v-model="form.password" label="Contraseña" prepend-inner-icon="mdi-form-textbox-password" :rules="rules.password" :type="show.password1 ? 'text' : 'password'" :append-icon="show.password1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show.password1 = !show.password1" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field v-model="form.password2" label="Confirmar contraseña" prepend-inner-icon="mdi-lock-reset" :rules="rules.password" :type="show.password2 ? 'text' : 'password'" :append-icon="show.password2 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show.password2 = !show.password2" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row align="center" justify="space-around" class="pa-3">
                  <v-btn large color="white" :disabled="loading" @click="back()">Volver</v-btn>
                  <v-btn large color="secondary" class="black--text" :disabled="loading || !isValid || completed || form.password == '' || form.password2 == '' || form.password != form.password2" @click="resetPassword()">Continuar</v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import "animate.css";
import { Loader, BasicAlert } from "@/components";

export default {
  name: "reset",
  components: {
    Loader,
    BasicAlert,
  },
  data: () => ({
    host: process.env.VUE_APP_API_SERVER,
    name: process.env.VUE_APP_NAME,
    logo: require("@/assets/logo.png"),
    path: "/pub/reset/password",
    loading: false,
    request: "",
    completed: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    form: {
      email: "",
      password: "",
    },
    show:{
      password1: false,
      password2: false,
    },
    rules: {
      password: [
        (v) => !!v || "El campo es requerido",
        (v) => (v && v.length >= 8) || "La contraseña debe contener mínimo 8 caracteres alfanuméricos, incluyendo al menos una letra y un número",
        (v) => (v && v.length <= 60) || "La contraseña excede la longitud máxima",
        (v) => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-zñÑ\d@$!%*#?&]{8,}$/.test(v) || "La contraseña debe contener mínimo 8 caracteres, incluyendo al menos una letra y un número",
      ],
    },
  }),
  mounted() {
    let id = this.$route.path.split("/");
    id = id[id.length - 1];
    let regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (regex.test(id)) {
      this.request = id;
    } else {
      this.alert.active = true;
      this.alert.color = "error";
      this.alert.msg = "La solicitud es incorrecta";
    }
  },

  computed: {
    isValid: function () {
      if (this.form.password != "" && this.form.password2 != "") {
        return this.$refs.formReset.validate();
      }
      return false;
    },
  },
  methods: {
    clear() {
      this.form.password = "";
      this.form.password2 = "";
    },
    resetPassword() {
      this.loading = true;
      let data = {
        codigo: this.request,
        password: this.form.password,
      };

      this.axios
        .post(this.host + this.path, { data })
        .then((response) => {
          this.clear();
          this.completed = true;
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "La contraseña ha sido actualizada";
          setTimeout(() => {
            this.$router.push("/login");
          }, 2500);
        })
        .catch((error) => {
          this.loading = false;
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message;
        })
        .finally(() => {});
    },
    back() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.main-title {
  font-size: 28px;
  color: #222665;
}
</style>
